<template>
  <div class="d-flex flex-wrap flex-column flex-sm-row">
    <v-card-title class="text-h6 font-weight-medium flex-grow-1">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon exact class="mr-2" :to="backTo" v-bind="attrs" v-on="on">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>

        <span>Voltar para lista</span>
      </v-tooltip>

      <span v-text="title" />
    </v-card-title>

    <div class="d-flex justify-end flex-grow-1 flex-sm-row flex-column">
      <div v-if="serie" class="d-sm-block d-flex">
        <v-card-title :class="titleClass">
          Serie
        </v-card-title>
        <v-card-subtitle :class="subtitleClass" v-text="serie" />
      </div>

      <div v-if="number" class="d-sm-block d-flex">
        <v-card-title :class="titleClass">
          Número
        </v-card-title>
        <v-card-subtitle :class="subtitleClass" v-text="number" />
      </div>

      <div v-if="issueDate" class="d-sm-block d-flex">
        <v-card-title :class="titleClass">
          Data de Emissão
        </v-card-title>
        <v-card-subtitle :class="subtitleClass">
          {{ issueDate | moment("L, LT") }}
        </v-card-subtitle>
      </div>

      <div v-if="authorizedAt" class="d-sm-block d-flex">
        <v-card-title :class="titleClass">
          Autorizado em
        </v-card-title>
        <v-card-subtitle :class="subtitleClass">
          {{ authorizedAt | moment("L, LT") }}
        </v-card-subtitle>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotaFiscalTitle",
  props: {
    title: String,
    backTo: [String, Object],
    serie: [Number, String],
    number: [Number, String],
    issueDate: Date,
    authorizedAt: Date,
  },
  computed: {
    titleClass() {
      let classes = ["primary--text", "text-button"];
      if (this.$vuetify.breakpoint.xsOnly) {
        classes.push("py-0");
      }
      return classes;
    },
    subtitleClass() {
      let classes = ["secondary--text", "font-weight-black", "text-righ"];
      if (this.$vuetify.breakpoint.xsOnly) {
        classes.push("ml-auto", "align-self-center", "pb-0", "pt-4");
      }
      return classes;
    },
  },
};
</script>
