<template>
  <div>
    <span v-if="text">
      <template v-if="value">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-text="value" v-bind="attrs" v-on="on" />
          </template>

          <span v-text="description" />
        </v-tooltip>
      </template>
      <span v-else>--</span>
    </span>

    <v-autocomplete
      v-else
      hide-no-data
      :error-messages="errors"
      :item-text="(item) => item.code + ' - ' + item.description"
      :item-value="(item) => item.code"
      :items="ncms"
      :label="label"
      :value="value"
      @blur="$v.value.$touch()"
      @input="$v.value.$touch()"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  name: "NcmField",
  props: {
    label: { type: String, default: "NCM / SH" },
    required: Boolean,
    text: Boolean,
    value: String,
  },
  validations: {
    value: {
      required: requiredIf("required"),
    },
  },
  computed: {
    errors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push("Campo obrigatório");
      return errors;
    },
    description() {
      if (this.value) {
        let ncm = this.ncm(this.value);
        return ncm?.description || "";
      }
      return "";
    },
    ...mapState({
      ncms: (state) => state.app.ncms,
    }),
    ...mapGetters(["ncm"]),
  },
  created() {
    this.$store.dispatch("fetchNCMs");
  },
};
</script>
