<template>
  <v-select
    :label="label"
    :items="items"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: "ProductUnitField",
  props: {
    value: String,
    label: {
      type: String,
      default: "Unidade de medida",
    },
  },
  computed: {
    isValueKnown() {
      if (!this.value) return true;
      for (const unit of this.knownValues) {
        if (unit.value === this.value) {
          return true;
        }
      }
      return false;
    },
    knownValues() {
      return [
        // Unidade de Medidas Tributáveis
        { text: "Grama", value: "g" },
        { text: "Jogo", value: "Jogo" },
        { text: "Kilograma", value: "KG" },
        { text: "Litro", value: "LT" },
        { text: "MegaWatt Hora", value: "MWHora" },
        { text: "Metro cúbico", value: "M3" },
        { text: "Metro quadrado", value: "M2" },
        { text: "Metro", value: "M" },
        { text: "Milheiro", value: "1000UN" },
        { text: "Pares", value: "Pares" },
        { text: "Quilate", value: "Quilat" },
        { text: "Unidade", value: "UN" },
      ];
    },
    items() {
      if (!this.isValueKnown) {
        return [
          ...this.knownValues,
          {
            text: this.value,
            value: this.value,
          },
        ];
      }
      return this.knownValues;
    },
  },
};
</script>
