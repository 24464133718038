<template>
  <div>
    <v-card-title :class="$vnode.data.staticClass">
      <template v-if="optional && value">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              class="mr-1"
              @click="$emit('input', null)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>

          <span>Remover cliente</span>
        </v-tooltip>
      </template>

      <span class="section-title"> Dados do {{ label }} </span>

      <span v-if="!readonly" class="caption">
        <span class="px-1">&dash;</span>

        <a
          v-if="showRecipient"
          class="text-decoration-underline"
          style="letter-spacing: normal !important"
          @click="changeType"
          v-text="
            isCompany
              ? label + ' é pessoa física'
              : label + ' é pessoa jurídica'
          "
        />

        <a
          v-else
          class="text-decoration-underline"
          style="letter-spacing: normal !important"
          @click="$emit('input', local)"
          >Inserir cliente</a
        >
      </span>
    </v-card-title>

    <v-card-text v-if="showRecipient">
      <v-row v-if="loadingRecipient">
        <v-col>
          <v-progress-linear indeterminate />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="3">
          <customer-field
            outlined
            hide-details="auto"
            :readonly="readonly"
            :required="!readonly"
            :disabled="loadingRecipient"
            :loading.sync="loadingRecipient"
            :type="local.type"
            :value="local.taxNumber"
            @input="updateValueKey('taxNumber', $event)"
            @update:customer="customerUpdated"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            outlined
            hide-details="auto"
            :readonly="readonly"
            :required="!readonly && !optional"
            :disabled="loadingRecipient"
            :label="label"
            :placeholder="'Nome do ' + label.toLowerCase()"
            :rules="nameRules"
            :value="local.name"
            @input="updateValueKey('name', $event)"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <phone-field
            outlined
            hide-details="auto"
            :readonly="readonly"
            :disabled="(readonly && !local.phone) || loadingRecipient"
            :required="!readonly && !optional"
            :value="local.phone"
            @input="updateValueKey('phone', $event)"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            outlined
            hide-details="auto"
            type="email"
            label="E-mail"
            prepend-inner-icon="mdi-email-outline"
            :readonly="readonly"
            :disabled="(readonly && !local.email) || loadingRecipient"
            :required="!readonly"
            :placeholder="'E-mail do ' + label.toLowerCase()"
            :value="local.email"
            @input="updateValueKey('email', $event)"
          />
        </v-col>
      </v-row>

      <v-row v-if="isCompany">
        <v-col cols="12" sm="5">
          <v-text-field
            outlined
            hide-details="auto"
            label="Razão Social"
            :readonly="readonly"
            :disabled="loadingRecipient"
            :required="!readonly"
            :value="local.fiscalName"
            :rules="[maxLengthRule(60)]"
            @input="updateValueKey('fiscalName', $event)"
          />
        </v-col>

        <v-col cols="12" sm="7">
          <state-tax-field
            outlined
            hide-details="auto"
            :required="!readonly"
            :readonly="readonly"
            :disabled="loadingRecipient"
            :value="local.stateTaxNumber"
            @input="updateValueKey('stateTaxNumber', $event)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card outlined :loading="loadingAddress">
            <v-card-title class="secondary--text text--lighten-2 text-button">
              Endereço do {{ label }}
            </v-card-title>

            <v-card-text>
              <nf-address
                outlined
                hide-details="auto"
                :optional="optional"
                :readonly="readonly"
                :disabled="loadingRecipient || loadingAddress"
                :street-placeholder="'Endereço do ' + label.toLowerCase()"
                :value="local.address"
                :loading.sync="loadingAddress"
                @input="updateValueKey('address', $event)"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import UpdateModel from "@/mixins/UpdateModel";
import Rules from "@/mixins/Rules";
import StateTaxField from "@/components/StateTaxField";
import CustomerField from "@/components/CustomerField";
import PhoneField from "@/components/PhoneField";
import NfAddress from "@/components/Address";

export default {
  name: "NotaFiscalRecipient",
  inheritAttrs: false,
  mixins: [UpdateModel, Rules],
  components: {
    StateTaxField,
    CustomerField,
    PhoneField,
    NfAddress,
  },
  props: {
    optional: Boolean,
    readonly: Boolean,
    label: String,
    value: Object,
  },
  data: () => ({
    loadingRecipient: false,
    loadingAddress: false,
  }),
  computed: {
    local() {
      return this.value || { type: "person" };
    },
    isCompany() {
      return this.local.type === "company";
    },
    showRecipient() {
      return this.value || !this.optional;
    },
    nameRules() {
      var rules = [];
      if (!this.optional && !this.readonly) {
        rules.push(this.notEmptyRule);
      }
      if (!this.readonly) {
        rules.push(this.maxLengthRule(60));
      }
      return rules;
    },
  },
  methods: {
    changeType() {
      this.updateValueKey("type", this.isCompany ? "person" : "company");

      // Clear all fields related to the type
      // Note: Emiting together with type cause issues in the MaskTextField
      // because it changes the cpf/cnpj mask
      this.$nextTick().then(() => {
        this.updateValueObject({
          taxNumber: "",
          fiscalName: "",
          stateTaxNumber: null,
        });
      });
    },
    customerUpdated(customer) {
      let data = {
        name: customer.name,
        phone: customer.phone,
        email: customer.email,
        fiscalName: customer.fiscalName,
        stateTaxNumber: customer.stateTaxNumber,
      };
      if (customer.addresses?.length > 0) {
        // for now let's use the first address
        [data.address] = customer.addresses;
      }
      this.updateValueObject(data);
    },
  },
};
</script>
