<template>
  <div>
    <v-card-title class="section-title">
      Totais da Nota Fiscal
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="5">
          <v-card flat>
            <v-card-title class="text-subtitle-2">
              Produtos
            </v-card-title>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Valor total dos produtos</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalLines"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Desconto</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalDiscount"
              />
            </v-card-text>

            <v-card-title class="text-subtitle-2">
              Valores adicionais
            </v-card-title>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Frete</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalShipping"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Despesas acessórias</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalAdditionalExpenses"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Seguro</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalInsurance"
              />
            </v-card-text>

            <v-card-title class="d-flex justify-space-between">
              <span class="text-subtitle-2">
                Total da Nota Fiscal
              </span>
              <currency
                class="primary--text text-subtitle-2"
                :value="totalNoTax"
              />
            </v-card-title>
          </v-card>
        </v-col>

        <v-col class="text-center">
          <v-divider :vertical="$vuetify.breakpoint.smAndUp" />
        </v-col>

        <v-col cols="12" sm="5">
          <v-card flat>
            <v-card-title class="text-subtitle-2">
              Cálculo de imposto dos produtos
            </v-card-title>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Base de cálculo do ICMS</span>
              <currency
                class="primary--text font-weight-medium"
                :value="taxBaseIcms"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Valor do ICMS</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalIcms"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Base de cálculo do ICMS-ST</span>
              <currency
                class="primary--text font-weight-medium"
                :value="taxBaseIcmsSt"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>Valor do ICMS-ST</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalIcmsSt"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>IPI</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalIpi"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>PIS</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalPis"
              />
            </v-card-text>
            <v-card-text class="d-flex justify-space-between py-1">
              <span>COFINS</span>
              <currency
                class="primary--text font-weight-medium"
                :value="totalCofins"
              />
            </v-card-text>

            <v-card-title class="d-flex justify-space-between">
              <span class="text-subtitle-2">
                Impostos da Nota Fiscal
              </span>
              <currency
                class="primary--text text-subtitle-2"
                :value="totalTax"
              />
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card flat tile color="grey lighten-4">
      <v-card-title
        class="d-flex px-8 text-button justify-center justify-sm-space-between"
      >
        <span>
          Total da Nota Fiscal + Impostos
        </span>
        <currency class="primary--text" :value="total" />
      </v-card-title>
    </v-card>

    <v-divider />
  </div>
</template>

<script>
import Decimal from "decimal.js";
import Currency from "@/components/Currency";

export default {
  name: "NotaFiscalTotal",
  components: { Currency },
  props: {
    totalLines: {
      type: [Number, Object],
      default: 0,
    },
    totalDiscount: {
      type: [Number, Object],
      default: 0,
    },
    totalShipping: {
      type: [Number, Object],
      default: 0,
    },
    totalAdditionalExpenses: {
      type: [Number, Object],
      default: 0,
    },
    totalInsurance: {
      type: [Number, Object],
      default: 0,
    },
    taxBaseIcms: {
      type: [Number, Object],
      default: 0,
    },
    totalIcms: {
      type: [Number, Object],
      default: 0,
    },
    taxBaseIcmsSt: {
      type: [Number, Object],
      default: 0,
    },
    totalIcmsSt: {
      type: [Number, Object],
      default: 0,
    },
    totalPis: {
      type: [Number, Object],
      default: 0,
    },
    totalCofins: {
      type: [Number, Object],
      default: 0,
    },
    totalIpi: {
      type: [Number, Object],
      default: 0,
    },
    total: {
      type: [Number, Object],
      default: 0,
    },
  },
  computed: {
    totalNoTax() {
      if (!this.totalLines) return 0;
      return Decimal(this.totalLines)
        .sub(this.totalDiscount)
        .add(this.totalShipping)
        .add(this.totalAdditionalExpenses)
        .add(this.totalInsurance);
    },
    totalTax() {
      return this.totalIcms
        .add(this.totalIcmsSt)
        .add(this.totalPis)
        .add(this.totalCofins)
        .add(this.totalIpi);
    },
  },
};
</script>
