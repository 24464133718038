<template>
  <cpf-cnpj-field
    :loading="loading"
    :disabled="loading"
    @change="changed"
    v-bind="$attrs"
  />
</template>

<script>
import Rules from "@/mixins/Rules";
import { HttpError } from "@/components/Error";
import CpfCnpjField from "@/components/CpfCnpjField";

export default {
  name: "CustomerField",
  mixins: [Rules],
  components: {
    CpfCnpjField,
  },
  props: {
    loading: Boolean,
  },
  data: () => ({}),
  computed: {},
  methods: {
    changed(taxNumber) {
      this.$emit("input", taxNumber);
      if (!taxNumber) return;

      this.$emit("update:loading", true);
      this.$http
        .get(`/v1/customers/${taxNumber}`)
        .then((res) => {
          let {
            tax_id: taxNumber,
            fiscal_name: fiscalName,
            state_tax_number: stateTaxNumber,
          } = res.data;

          delete res.data.tax_id;
          delete res.data.fiscal_name;
          delete res.data.state_tax_number;
          res.data.addresses.map((addr) => {
            let { city } = addr;
            city.state = city.state_code;
            delete city.state_code;
            return Object.assign(addr, city);
          });

          this.$emit(
            "update:customer",
            Object.assign(res.data, {
              taxNumber,
              fiscalName,
              stateTaxNumber,
            })
          );
        })
        .catch((err) => {
          if (err instanceof HttpError) {
            switch (err.code) {
              case "not_found":
                return;
            }
          } else {
            console.error(err);
          }
          this.$sentry.captureException(err);
        })
        .then(() => {
          this.$emit("update:loading", false);
        });
    },
  },
};
</script>
