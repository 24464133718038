<template>
  <div>
    <v-card-title class="section-title">
      Dados Sobre a Nota Fiscal
    </v-card-title>

    <v-card-text>
      <v-row v-if="!readonly || !!value.fiscal">
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            hide-details
            label="Informações Obrigatórias do Contribuinte"
            :rows="isSimple ? 1 : 3"
            :disabled="isSimple && !readonly"
            :readonly="readonly"
            :value="local.fiscal"
            @input="updateValueKey('fiscal', $event)"
          />
        </v-col>
      </v-row>

      <v-row v-if="!readonly || !!value.additional">
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            hide-details="auto"
            label="Informações Complementares do Contribuinte"
            :counter="!readonly && 1000"
            :rows="readonly ? 1 : 3"
            :readonly="readonly"
            :rules="rules"
            :value="local.additional"
            @input="updateValueKey('additional', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UpdateModel from "@/mixins/UpdateModel";
import Rules from "@/mixins/Rules";

export default {
  name: "NotaFiscalAdditionalData",
  mixins: [UpdateModel, Rules],
  props: {
    readonly: Boolean,
    value: Object,
  },
  computed: {
    ...mapState("auth", ["company"]),
    isSimple() {
      return this.company?.fiscalRegime === "simple";
    },
    local() {
      return this.value || {};
    },
    rules() {
      if (!this.readonly) return [this.maxLengthRule(1000)];
    },
  },
  created() {
    if (!this.local.fiscal && this.isSimple) {
      this.updateValueKey(
        "fiscal",
        "DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL NAO GERA DIREITO A CREDITO FISCAL DE IPI."
      );
    }
  },
};
</script>
