<template>
  <v-dialog
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="computedWidth"
    v-model="show"
  >
    <v-card :loading="loading">
      <v-card-title class="text-button">
        Informações fiscais
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  disabled
                  hide-details
                  label="Código do produto"
                  :rules="[notEmptyRule]"
                  :readonly="readonly"
                  :required="!readonly"
                  v-model="local.product.id"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <unit-field
                  outlined
                  hide-details
                  :readonly="readonly"
                  :required="!readonly"
                  :rules="[notEmptyRule]"
                  v-model="local.unit"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  hide-details
                  label="Informações complementares"
                  :readonly="readonly"
                  :disabled="readonly && !local.notes"
                  v-model="local.notes"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="3">
                <ncm-field
                  hide-details
                  outlined
                  required
                  :disabled="hasNcm && !readonly"
                  :readonly="readonly"
                  v-model="local.ncm"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <cest-field
                  hide-details
                  outlined
                  :disabled="hasNcm"
                  :readonly="readonly"
                  v-model="local.cest"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <cfop-field
                  hide-details
                  outlined
                  :applications="cfopApplications"
                  :readonly="readonly"
                  :required="!readonly"
                  v-model="local.cfop"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  hide-details
                  label="Código de barras (EAN)"
                  :readonly="readonly"
                  :disabled="readonly && !local.barcode"
                  v-model="local.barcode"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  outlined
                  hide-details
                  label="Origem"
                  :readonly="readonly"
                  :required="!readonly"
                  :item-text="selectItemText"
                  :items="goodsOrigins"
                  :rules="[notEmptyRule]"
                  v-model="local.goodsOrigin"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-title
                    class="secondary--text text--lighten-2 text-button pb-0"
                  >
                    Configuração de tributos
                  </v-card-title>

                  <v-card-text flat>
                    <v-tabs v-model="taxTab" color="primary">
                      <v-tabs-slider color="primary"></v-tabs-slider>

                      <v-tab key="icms">
                        <v-badge
                          dot
                          bordered
                          color="error"
                          :value="!local.icmsTaxCode"
                        >
                          ICMS
                        </v-badge>
                      </v-tab>

                      <v-tab key="ipi">
                        <v-badge dot bordered color="error" :value="false">
                          IPI
                        </v-badge>
                      </v-tab>

                      <v-tab key="pis">
                        <v-badge
                          dot
                          bordered
                          color="error"
                          :value="!local.pisTaxCode"
                        >
                          PIS
                        </v-badge>
                      </v-tab>

                      <v-tab key="cofins">
                        <v-badge
                          dot
                          bordered
                          color="error"
                          :value="!local.cofinsTaxCode"
                        >
                          COFINS
                        </v-badge>
                      </v-tab>
                    </v-tabs>

                    <v-divider />

                    <v-card flat>
                      <v-card-text class="px-2">
                        <v-tabs-items v-model="taxTab">
                          <v-tab-item key="icms">
                            <v-row class="pt-1">
                              <v-col cols="12" sm="8">
                                <v-select
                                  ref="icms"
                                  outlined
                                  required
                                  label="Situação Tributária ICMS"
                                  :hide-details="showIcmsTax"
                                  :readonly="readonly"
                                  :item-text="selectItemText"
                                  :items="icmsTaxCodes"
                                  :rules="[hasSelectionRule('icms', true)]"
                                  v-model="local.icmsTaxCode"
                                />
                              </v-col>

                              <v-col v-if="showIcmsTax" cols="12" sm="4">
                                <number-field
                                  outlined
                                  hide-details
                                  label="Total ICMS"
                                  prefix="R$"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.icmsTotal"
                                />
                              </v-col>
                            </v-row>

                            <v-row v-if="showIcmsTax">
                              <v-col cols="12" sm="6">
                                <v-select
                                  outlined
                                  label="Modalidade Base de Cálculo"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :item-text="selectItemText"
                                  :items="icmsTaxBaseModes"
                                  v-model="local.icmsTaxBaseMode"
                                />
                              </v-col>

                              <v-col cols="12" md="3" sm="6">
                                <number-field
                                  outlined
                                  label="Base de Cálculo"
                                  prefix="R$"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.icmsTaxBase"
                                />
                              </v-col>

                              <v-col cols="12" md="3" sm="6">
                                <number-field
                                  outlined
                                  label="Alíquota"
                                  suffix="%"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.icmsTaxRate"
                                />
                              </v-col>
                            </v-row>
                          </v-tab-item>

                          <v-tab-item key="ipi">
                            <v-row class="pt-1">
                              <v-col cols="12" sm="8">
                                <v-select
                                  ref="ipi"
                                  outlined
                                  clearable
                                  label="Situação Tributária IPI"
                                  :hide-details="showIpiTax"
                                  :readonly="readonly"
                                  :disabled="readonly && !local.ipiTaxCode"
                                  :item-text="selectItemText"
                                  :items="ipiTaxCodes"
                                  :rules="[hasSelectionRule('ipi', false)]"
                                  v-model="local.ipiTaxCode"
                                />
                              </v-col>
                            </v-row>
                          </v-tab-item>

                          <v-tab-item key="pis">
                            <v-row class="pt-1">
                              <v-col cols="12" sm="8">
                                <v-select
                                  ref="pis"
                                  outlined
                                  required
                                  label="Situação Tributária PIS"
                                  :hide-details="showPisTax"
                                  :readonly="readonly"
                                  :item-text="selectItemText"
                                  :items="pisCofinsTaxCodes"
                                  :rules="[hasSelectionRule('pis', true)]"
                                  v-model="local.pisTaxCode"
                                />
                              </v-col>

                              <v-col v-if="showPisTax" cols="12" sm="4">
                                <number-field
                                  outlined
                                  hide-details
                                  label="Total PIS"
                                  prefix="R$"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.pisTotal"
                                />
                              </v-col>
                            </v-row>

                            <v-row v-if="showPisTax">
                              <v-col cols="12" sm="3">
                                <number-field
                                  outlined
                                  label="Base de Cálculo"
                                  prefix="R$"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.pisTaxBase"
                                />
                              </v-col>

                              <v-col cols="12" sm="3">
                                <number-field
                                  outlined
                                  label="Alíquota"
                                  suffix="%"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.pisTaxRate"
                                />
                              </v-col>
                            </v-row>
                          </v-tab-item>

                          <v-tab-item key="cofins">
                            <v-row class="pt-1">
                              <v-col cols="12" sm="8">
                                <v-select
                                  ref="cofins"
                                  outlined
                                  required
                                  label="Situação Tributária COFINS"
                                  :hide-details="showCofinsTax"
                                  :readonly="readonly"
                                  :item-text="selectItemText"
                                  :items="pisCofinsTaxCodes"
                                  :rules="[hasSelectionRule('cofins', true)]"
                                  v-model="local.cofinsTaxCode"
                                />
                              </v-col>

                              <v-col v-if="showCofinsTax" cols="12" sm="4">
                                <number-field
                                  outlined
                                  hide-details
                                  label="Total COFINS"
                                  prefix="R$"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.cofinsTotal"
                                />
                              </v-col>
                            </v-row>

                            <v-row v-if="showCofinsTax">
                              <v-col cols="12" sm="3">
                                <number-field
                                  outlined
                                  label="Base de Cálculo"
                                  prefix="R$"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.cofinsTaxBase"
                                />
                              </v-col>

                              <v-col cols="12" sm="3">
                                <number-field
                                  outlined
                                  label="Alíquota"
                                  suffix="%"
                                  :readonly="readonly"
                                  :required="!readonly"
                                  :value="local.cofinsTaxRate"
                                />
                              </v-col>
                            </v-row>
                          </v-tab-item>
                        </v-tabs-items>

                        <v-row v-if="fiscalHasChanges">
                          <v-col>
                            <v-checkbox
                              hide-details
                              class="mt-0"
                              label="Usar estas informações fiscais para produtos com o mesmo NCM"
                              v-model="fiscalDataNcmDefault"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-6 justify-center">
        <v-btn depressed @click="$emit('close')">
          <span v-if="readonly">Fechar</span>
          <span v-else>Cancelar</span>
        </v-btn>

        <v-btn v-if="!readonly" large color="success" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { HttpError } from "@/components/Error";
import CestField from "@/components/CestField";
import CfopField from "@/components/CfopField";
import NcmField from "@/components/NcmField";
import NumberField from "@/components/NumberField";
import Rules from "@/mixins/Rules";
import UnitField from "@/components/Product/UnitField.vue";
import UpdateModel from "@/mixins/UpdateModel";

export default {
  name: "NotaFiscalProductEdit",
  mixins: [UpdateModel, Rules],
  components: {
    CfopField,
    NcmField,
    CestField,
    NumberField,
    UnitField,
  },
  props: {
    readonly: Boolean,
    value: Object,
    fiscalRegime: {
      required: true,
      type: String,
    },
    nfeOperation: String,
    interstate: Boolean,
    cfopApplications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      line: this.updateObject(this.value, {}),
      taxTab: "icms",
      hasNcm: !!this.value?.ncm,
      fiscalDataNcmDefault: false,
    };
  },
  computed: {
    local() {
      if (!this.line) {
        return { product: {} };
      }
      if (!this.line.product) {
        this.line = { ...this.line, product: {} };
      }
      return this.line;
    },
    show: {
      get() {
        return !!this.value;
      },
      set(v) {
        if (!v) {
          this.$emit("close");
        }
      },
    },
    computedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "90%";
        case "md":
          return "75%";
        case "lg":
          return "60%";
        case "xl":
          return "35%";
      }
      return "auto";
    },
    goodsOrigins() {
      return [
        { value: 0, description: "Nacional" },
        { value: 1, description: "Estrangeiro - Adquirida no mercado interno" },
        { value: 2, description: "Estrangeiro - Importação direta" },
      ];
    },
    icmsTaxCodes() {
      if (this.fiscalRegime === "simple") {
        return this.icmsTaxCodesSimple;
      }
      return this.icmsTaxCodesNormal;
    },
    showIcmsTax() {
      const taxCodes = [
        // fiscal regime = normal
        "00",
        "10",
        "20",
        "51",
        "70",
        "90",
        // fiscal regime = simple
        "900",
      ];
      return taxCodes.includes(this.local.icmsTaxCode);
    },
    icmsTaxCodesSimple() {
      return [
        {
          value: "101",
          description:
            "Tributada pelo Simples Nacional com permissão de crédito",
        },
        {
          value: "102",
          description:
            "Tributada pelo Simples Nacional sem permissão de crédito",
        },
        {
          value: "103",
          description:
            "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
        },
        {
          value: "201",
          description:
            "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por Substituição Tributária",
        },
        {
          value: "202",
          description:
            "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por Substituição Tributária ",
        },
        {
          value: "203",
          description:
            "Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por Substituição Tributária",
        },
        { value: "300", description: "Imune" },
        {
          value: "400",
          description: "Não tributada pelo Simples Nacional",
        },
        {
          value: "500",
          description:
            "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
        },
        { value: "900", description: "Outros" },
      ];
    },
    icmsTaxCodesNormal() {
      return [
        { value: "00", description: "Tributada integralmente" },
        {
          value: "10",
          description:
            "Tributada e com cobrança do ICMS por substituição tributária",
        },
        { value: "20", description: "Com redução de base de cálculo" },
        {
          value: "30",
          description:
            "Isenta ou não tributada e com cobrança do ICMS por substituição tributária",
        },
        { value: "40", description: "Isenta" },
        { value: "41", description: "Não tributada" },
        { value: "50", description: "Suspensão" },
        {
          value: "51",
          description:
            "Diferimento A exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF.",
        },
        {
          value: "60",
          description: "ICMS cobrado anteriormente por substituição tributária",
        },
        {
          value: "70",
          description:
            "Com redução de base de cálculo e cobrança do ICMS por substituição tributária",
        },
        { value: "90", description: "Outros" },
      ];
    },
    icmsTaxBaseModes() {
      return [
        { value: 0, description: "Margem Valor Agregado (%)" },
        { value: 1, description: "Pauta (Valor)" },
        { value: 2, description: "Preço Tabelado Máx. (valor)" },
        { value: 3, description: "Valor da operação" },
      ];
    },
    showIpiTax() {
      const taxCodes = ["00", "49", "50", "99"];
      return taxCodes.includes(this.local.ipiTaxCode);
    },
    ipiTaxCodes() {
      return [
        {
          value: "00",
          description: "Entrada com recuperação de crédito",
        },
        {
          value: "01",
          description: "Entrada tributada com alíquota zero",
        },
        {
          value: "02",
          description: "Entrada isenta",
        },
        {
          value: "03",
          description: "Entrada não-tributada",
        },
        {
          value: "04",
          description: "Entrada imune",
        },
        {
          value: "05",
          description: "Entrada com suspensão",
        },
        {
          value: "49",
          description: "Outras entradas",
        },
        {
          value: "50",
          description: "Saída tributada",
        },
        {
          value: "51",
          description: "Saída tributada com alíquota zero",
        },
        {
          value: "52",
          description: "Saída isenta",
        },
        {
          value: "53",
          description: "Saída não-tributada",
        },
        {
          value: "54",
          description: "Saída imune",
        },
        {
          value: "55",
          description: "Saída com suspensão",
        },
        {
          value: "99",
          description: "Outras saídas",
        },
      ];
    },
    showPisCofinsTax() {
      return [
        "01",
        "02",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "98",
        "99",
      ];
    },
    showPisTax() {
      return this.showPisCofinsTax.includes(this.local.pisTaxCode);
    },
    showCofinsTax() {
      return this.showPisCofinsTax.includes(this.local.cofinsTaxCode);
    },
    pisCofinsTaxCodes() {
      return [
        {
          value: "01",
          description:
            "Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))",
        },
        {
          value: "02",
          description:
            "Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))",
        },
        {
          value: "03",
          description:
            "Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)",
        },
        {
          value: "04",
          description:
            "Operação Tributável (tributação monofásica (alíquota zero))",
        },
        {
          value: "05",
          description: "Operação Tributável (Substituição Tributária)",
        },
        { value: "06", description: "Operação Tributável (alíquota zero);" },
        { value: "07", description: "Operação Isenta da Contribuição" },
        { value: "08", description: "Operação Sem Incidência da Contribuição" },
        { value: "09", description: "Operação com Suspensão da Contribuição" },
        { value: "49", description: "Outras Operações de Saída" },
        {
          value: "50",
          description:
            "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
        },
        {
          value: "51",
          description:
            "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno",
        },
        {
          value: "52",
          description:
            "Operação com Direito a Crédito – Vinculada Exclusivamente a Receita de Exportação",
        },
        {
          value: "53",
          description:
            "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
        },
        {
          value: "54",
          description:
            "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
        },
        {
          value: "55",
          description:
            "Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
        },
        {
          value: "56",
          description:
            "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação",
        },
        {
          value: "60",
          description:
            "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
        },
        {
          value: "61",
          description:
            "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
        },
        {
          value: "62",
          description:
            "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação",
        },
        {
          value: "63",
          description:
            "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
        },
        {
          value: "64",
          description:
            "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
        },
        {
          value: "65",
          description:
            "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não- Tributadas no Mercado Interno e de Exportação",
        },
        {
          value: "66",
          description:
            "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação",
        },
        { value: "67", description: "Crédito Presumido - Outras Operações" },
        {
          value: "70",
          description: "Operação de Aquisição sem Direito a Crédito",
        },
        { value: "71", description: "Operação de Aquisição com Isenção" },
        { value: "72", description: "Operação de Aquisição com Suspensão" },
        { value: "73", description: "Operação de Aquisição a Alíquota Zero" },
        {
          value: "74",
          description: "Operação de Aquisição; sem Incidência da Contribuição",
        },
        {
          value: "75",
          description: "Operação de Aquisição por Substituição Tributária",
        },
        { value: "98", description: "Outras Operações de Entrada" },
        { value: "99", description: "Outras Operações" },
      ].map((taxCode) => {
        return Object.assign(taxCode, {
          text: taxCode.value + " - " + taxCode.description,
        });
      });
    },
    fiscalHasChanges() {
      if (!this.value || !this.line) return false;
      return (
        this.value.cfop != this.line.cfop ||
        this.value.icmsTaxCode != this.line.icmsTaxCode ||
        this.value.pisTaxCode != this.line.pisTaxCode ||
        this.value.cofinsTaxCode != this.line.cofinsTaxCode ||
        this.value.ipiTaxCode != this.line.ipiTaxCode
      );
    },
  },
  watch: {
    value(v) {
      if (!v) {
        this.loading = false;
        this.taxTab = "icms";
      } else {
        this.line = this.updateObject(v, {
          product: this.updateObject(v.product, {}),
        });
        this.hasNcm = !!v.ncm;
      }
    },
  },
  methods: {
    hasSelectionRule(field, required) {
      if (!this.$refs[field]) {
        return true;
      }

      const { selectedItems } = this.$refs[field];
      return (
        !required || selectedItems.length > 0 || "Campo não pode ser vazio"
      );
    },
    selectItemText: (item) => item.value + " - " + item.description,
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      if (!this.fiscalHasChanges) {
        this.$nextTick().then(() => {
          this.$emit("close");
        });
        return;
      }

      try {
        const { id } = this.line.product;
        await this.$http.put(`/v1/products/${id}/fiscal`, {
          ncm: this.line.ncm,
          applications: [this.nfeOperation],
          interstate: this.interstate,
          cfop: this.line.cfop,
          icms_tax_code: this.line.icmsTaxCode,
          pis_tax_code: this.line.pisTaxCode,
          cofins_tax_code: this.line.cofinsTaxCode,
          ipi_tax_code: this.line.ipiTaxCode || null,
          ncm_default: this.fiscalDataNcmDefault,
        });

        this.updateValueObject(this.line);
        this.$nextTick().then(() => {
          this.$emit("close");
        });
      } catch (err) {
        if (err instanceof HttpError) {
          switch (err.code) {
            case "missing_field":
              var msg;
              switch (err.field) {
                case "cfop":
                  msg = "O campo CFOP não foi preenchido";
                  break;
                case "pis_tax_code":
                  msg = "O campo Situação Tributária PIS não foi preenchido";
                  break;
                case "cofins_tax_code":
                  msg = "O campo Situação Tributária COFINS não foi preenchido";
                  break;
                default:
                  msg = `O campo "${err.field}" não foi preenchido`;
              }

              this.notifyError(msg);
              break;
            default:
              this.$sentry.captureException(err);
              this.notifyError(
                "Ocorreu um erro ao salvar as Informações Fiscais 😢"
              );
          }
        } else {
          console.error(err);
          this.$sentry.captureException(err);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
