<template>
  <v-text-field
    ref="input"
    type="text"
    v-bind="$attrs"
    v-currency="options"
    :readonly="readonly"
    :value="formattedValue"
    @change="onChange"
    @input="onInput"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]>
      <slot :name="slot" />
    </template>
  </v-text-field>
</template>

<script>
import Decimal from "decimal.js";

export default {
  name: "NumberField",
  props: {
    precision: { type: [Number, Object], default: undefined },
    readonly: Boolean,
    value: [Number, String],
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  computed: {
    options() {
      let opts = {
        locale: "pt-BR",
        precision: this.precision,
        distractionFree: {},
      };
      if (!this.readonly) {
        return opts;
      }

      let distractionFree = {
        hideNegligibleDecimalDigits: false,
      };
      return Object.assign(opts, { distractionFree });
    },
    isDataSynced() {
      let d1 = new Decimal(this.data || 0);
      let d2 = new Decimal(this.dataSynced || 0);
      return d1.eq(d2);
    },
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  mounted() {
    this.setValue(this.value);
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, Number(value));
    },
    onInput(value) {
      var vStr = this.$ci.getValue(this.$refs.input);
      this.$emit("input", vStr?.toString());
      this.formattedValue = value;
    },
    onChange(value) {
      var vStr = this.$ci.getValue(this.$refs.input);
      this.$emit("change", vStr?.toString());
      this.formattedValue = value;
    },
  },
};
</script>
