<template>
  <v-col cols="12" v-if="banner">
    <v-alert
      outlined
      prominent
      border="left"
      :color="banner.color"
      :icon="banner.icon"
    >
      <div class="black--text">
        <v-card-title class="font-weight-medium" style="word-break: break-word">
          <span v-text="banner.title" />
          <span
            v-if="canceled && canceled.reason"
            class="font-weight-light secondary--text text--lighten-2"
          >
            <span class="ml-1 d-none d-sm-inline-block">
              &mdash;
            </span>
            {{ canceled.reason }}
          </span>
        </v-card-title>

        <v-card-text class="pb-0" v-if="value === 'send'">
          <p>
            Sua nota é válida e já pode ser enviada à Secretaria da Fazenda.
          </p>

          <p v-if="localRecipient.email">
            <strong>
              Assim que transmitir, nosso sistema enviará o {{ pdfName }} e o
              XML automaticamente para:
            </strong>
            <span class="d-block font-weight-light">
              {{ localRecipient.email }}
            </span>
          </p>
        </v-card-text>

        <v-card-text class="pb-0" v-if="value === 'sent'">
          <p>
            Sua nota já foi enviada à Secretaria da Fazenda e possui a seguinte
            Chave de Acesso:
            <span class="d-flex flex-wrap font-weight-light">
              <span
                class="pr-1"
                v-for="part in sefazIdParts"
                :key="part"
                v-text="part"
              />
            </span>
          </p>

          <p v-if="localRecipient.email">
            <strong>
              Nosso sistema enviou o {{ pdfName }} e o XML automaticamente para:
            </strong>

            <span class="d-block font-weight-light">
              <span class="pr-1" v-text="localRecipient.email" />
              <v-btn outlined x-small disabled>
                Reenviar
              </v-btn>
            </span>
          </p>
        </v-card-text>

        <v-card-text class="pb-0" v-if="value === 'receive'">
          <p>
            O XML da nota fiscal foi importada com sucesso, selecione os
            produtos para serem creditados no estoque ou crie novos produtos
            utilizando os dados do seu fornecedor e clique em
            <strong>Receber</strong> no final da página.
          </p>

          <p>
            Chave de Acesso da Nota Fiscal:
            <span class="d-flex flex-wrap font-weight-light">
              <span
                class="pr-1"
                v-for="part in sefazIdParts"
                :key="part"
                v-text="part"
              />
            </span>
          </p>
        </v-card-text>

        <v-card-text class="pb-0" v-if="value === 'received'">
          <p>
            A nota fiscal foi importada com sucesso, todos os produtos foram
            creditados no estoque e o preço de custo médio foi atualizado.
          </p>

          <p>
            Chave de Acesso da Nota Fiscal:
            <span class="d-flex flex-wrap font-weight-light">
              <span
                class="pr-1"
                v-for="part in sefazIdParts"
                :key="part"
                v-text="part"
              />
            </span>
          </p>
        </v-card-text>

        <v-card-text class="pb-0" v-if="value === 'canceled'">
          <div>
            Sua nota foi cancelada junto à Secretaria da Fazenda em
            <strong>{{ canceled.canceled_at | moment("LLL") }}</strong>
            através do protocolo
            <strong> {{ canceled.protocol }} </strong>.
          </div>

          <p>
            Chave de Acesso:
            <span class="d-flex flex-wrap font-weight-light">
              <span
                class="mr-1"
                v-for="part in sefazIdParts"
                :key="part"
                v-text="part"
              />
            </span>
          </p>

          <p v-if="localRecipient.email">
            <strong>
              Nosso sistema enviou o {{ pdfName }} e o XML do cancelamento
              automaticamente para:
            </strong>

            <span class="d-block font-weight-light">
              <span class="pr-1" v-text="localRecipient.email" />
              <v-btn outlined x-small disabled>
                Reenviar
              </v-btn>
            </span>
          </p>
        </v-card-text>

        <v-card-actions :class="{ 'justify-center flex-column': xsOnly }">
          <v-btn
            v-if="value === 'send'"
            color="success"
            :loading="loading"
            :small="xsOnly"
            :block="xsOnly"
            :large="!xsOnly"
            @click="$emit('click:save')"
          >
            <v-icon left>mdi-send</v-icon>
            Transmitir Nota Fiscal
          </v-btn>

          <template
            v-else-if="['sent', 'received', 'canceled'].includes(value)"
          >
            <v-btn
              v-if="pdfUrl"
              depressed
              :target="!isStandalone && '_blank'"
              :class="{ 'mb-3': xsOnly }"
              :small="xsOnly"
              :block="xsOnly"
              :href="pdfUrl"
            >
              <v-icon left>mdi-pdf-box</v-icon>
              <span>Download do {{ pdfName }} (PDF)</span>
            </v-btn>

            <v-btn
              v-if="xmlUrl"
              depressed
              :small="xsOnly"
              :block="xsOnly"
              :href="xmlUrl"
              :style="xsOnly && 'margin-left: 0'"
            >
              <v-icon left>mdi-xml</v-icon>
              <span>Download do XML</span>
            </v-btn>
          </template>
        </v-card-actions>
      </div>
    </v-alert>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import FormatterFilter from "@/mixins/FormatterFilter";

export default {
  name: "NotaFiscalBanner",
  mixins: [FormatterFilter],
  props: {
    nfType: String,
    value: String,
    loading: Boolean,
    recipient: Object,
    sefazId: String,
    canceled: Object,
    pdfUrl: String,
    xmlUrl: String,
  },
  computed: {
    banner() {
      switch (this.value) {
        case "send":
          return this.sendBanner;
        case "sent":
          return this.sentBanner;
        case "receive":
          return this.receiveBanner;
        case "received":
          return this.receivedBanner;
        case "canceled":
          return this.canceledBanner;
      }
      return "";
    },
    sendBanner() {
      return {
        color: "success",
        icon: "mdi-truck-fast-outline",
        title: "Nota fiscal pronta para ser transmitida! 👍",
      };
    },
    sentBanner() {
      return {
        color: "success",
        icon: !this.xsOnly && "mdi-check-all",
        title: "Nota fiscal transmitida com sucesso! 🎉",
      };
    },
    receiveBanner() {
      return {
        color: "success",
        icon: !this.xsOnly && "mdi-application-import",
        title: "Nota fiscal pronta para ser recebida! 👍",
      };
    },
    receivedBanner() {
      return {
        color: "success",
        icon: !this.xsOnly && "mdi-check-all",
        title: "Nota fiscal recebida com sucesso! 🎉",
      };
    },
    canceledBanner() {
      return {
        color: "error",
        icon: !this.xsOnly && "mdi-file-document",
        title: "🚫 Nota fiscal cancelada",
      };
    },
    sefazIdParts() {
      return this.sefazId?.match(/.{4}/g);
    },
    localRecipient() {
      return this.recipient || {};
    },
    pdfName() {
      return this.nfType === "nfce" ? "DANFCE" : "DANFE";
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapGetters(["isStandalone"]),
  },
};
</script>
